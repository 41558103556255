// src/HomePage.tsx
import React, { useEffect, useState } from 'react'
import styles from './Header.module.scss'
import { ref, getDownloadURL, listAll } from 'firebase/storage'
import { storage } from '../../config/firebaseconfig.js'

interface HeaderPageProps {
  company: string
}

export const Header: React.FC<HeaderPageProps> = ({ company }) => {
  const [logoUrl, setLogoUrl] = useState<string>('')
  const [videoUrl, setVideoUrl] = useState<string>('')

  useEffect(() => {
    const fetchImages = async () => {
      const folderRef = ref(storage, `images/${company}/`)
      const res = await listAll(folderRef)

      res.items.forEach((itemRef) => {
        getDownloadURL(itemRef)
          .then((item) => {
            if (itemRef.name.includes('logo')) {
              setLogoUrl(item)
            } else if (itemRef.name.includes('video')) {
              setVideoUrl(item)
            }
          })
          .catch(() => {})
      })
    }
    fetchImages()
  }, [])

  return (
    <div>
      <div className={styles.titleContainer}>
        <h1>Hello</h1>
        {logoUrl && <img src={logoUrl} alt="Uploaded" />}
        <h1>Team</h1>
      </div>
      <div className={styles.videoContainer}>{videoUrl && <video src={videoUrl} controls autoPlay={true} muted={false} />}</div>
    </div>
  )
}

// src/HomePage.tsx
import React from 'react'
import styles from './AboutMe.module.scss'
import travelPicture from '../../assets/images/leontravel.jpg'
import { soccer, code, bike, travel, run } from '../../assets/icons/icons'

interface AboutMePageProps {}

export const AboutMe: React.FC<AboutMePageProps> = () => {
  return (
    <div className={styles.aboutmeContainer}>
      <div className={styles.info}>
        <p>
          I’m <strong>Leon</strong>, 27 and a developer from Germany. I like to design and build WebApps and Websites. I've been
          interested in programming since my youth and my first experiences came from building my school's website. Besides my passion
          for coding, I like to travel, biking, running marathons and playing soccer.
        </p>
        <div className={styles.icons}>
          <div className={styles.icon}>{bike}</div>
          <div className={styles.icon}>{code}</div>
          <div className={styles.icon}>{run}</div>
          <div className={styles.icon}>{soccer}</div>
          <div className={styles.icon}>{travel}</div>
        </div>
      </div>
      <div className={styles.image}>
        <img src={travelPicture} alt="Leon Travel" />
      </div>
    </div>
  )
}

// src/HomePage.tsx
import React from 'react'
import styles from './CarrerSkills.module.scss'
import MqSQL from '../../assets/logos/MqSQL.png'
import Jira from '../../assets/logos/Jira.png'
import awslogo from '../../assets/logos/awslogo.png'
import nodeJS from '../../assets/logos/nodeJS.png'
import firebase from '../../assets/logos/firebase.png'
import phalcon from '../../assets/logos/phalcon.png'
import reactlogo from '../../assets/logos/reactlogo.png'
import wordpress from '../../assets/logos/wordpress.png'
import TypescriptLogo from '../../assets/logos/TypescriptLogo.png'
import GitLogo from '../../assets/logos/GitLogo.png'

interface CarrerSkillsPageProps {}

export const CarrerSkills: React.FC<CarrerSkillsPageProps> = () => {
  return (
    <div className={styles.carrerContainer}>
      <div className={styles.info}>
        <h3>Study & Erasmus </h3>
        <p>
          I studied media informatics in Osnabrück. During this time, I was already interested in the web and created various web
          projects. One project I am really proud of was the website{' '}
          <a href="https://www.om-online.de/om/steinfelder-wollen-betriebe-unterstuetzen-37084">steinfelder-helfen.de</a> during
          Corona-19. Through this platform, people could purchase vouchers that generated several thousand euros in sales and helped
          companies, especially hairdressers in our village, to stay afloat.
        </p>
        <p>
          In 2022, I decided to go to Amsterdam for an Erasmus course and took the Mobile Development minor there. We also had the
          opportunity to implement a project at Vormats with a group of students.
        </p>
        <p>
          After my Erasmus in Amsterdam, I started as an intern at Vormats. During this time, I worked on the frontend with React. This
          is where I was able to bring my creativity into play.
        </p>
      </div>
      <h3>Fullstack at Vormats</h3>
      <p>
        Vormats is a web application for storing, editing and sharing video. I started there as a Frontend Developer and worked with
        React. I was involved in the entire process, from brainstorming new ideas to implementing features to maintenance and bug
        fixing.. After some time I moved into backend development. There I worked with Phalcon and shortly afterwards with the
        migration from Phalcon to NodeJs.
      </p>
      <h3>Some Technologies I worked with</h3>

      <div className={styles.technologies}>
        <img src={GitLogo} className={styles.tec} />
        <img src={MqSQL} className={styles.tec} />
        <img src={Jira} className={styles.tec} />
        <img src={awslogo} className={styles.tec} />
        <img src={firebase} className={styles.tec} />
        <img src={nodeJS} className={styles.tec} />
        <img src={phalcon} className={styles.tec} />
        <img src={reactlogo} className={styles.tec} />
        <img src={TypescriptLogo} className={styles.tec} />
        <img src={wordpress} className={styles.tec} />
      </div>
    </div>
  )
}

export const bike = (
  <svg width="55" height="51" viewBox="0 0 55 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.25 50.75C5.0368 50.75 0 45.7133 0 39.5C0 33.2867 5.0368 28.25 11.25 28.25C17.4632 28.25 22.5 33.2867 22.5 39.5C22.5 45.7133 17.4632 50.75 11.25 50.75ZM11.25 45.75C14.7018 45.75 17.5 42.9517 17.5 39.5C17.5 36.0483 14.7018 33.25 11.25 33.25C7.79822 33.25 5 36.0483 5 39.5C5 42.9517 7.79822 45.75 11.25 45.75ZM43.75 50.75C37.5367 50.75 32.5 45.7133 32.5 39.5C32.5 33.2867 37.5367 28.25 43.75 28.25C49.9633 28.25 55 33.2867 55 39.5C55 45.7133 49.9633 50.75 43.75 50.75ZM43.75 45.75C47.2017 45.75 50 42.9517 50 39.5C50 36.0483 47.2017 33.25 43.75 33.25C40.2983 33.25 37.5 36.0483 37.5 39.5C37.5 42.9517 40.2983 45.75 43.75 45.75ZM25.0575 24.0135L30 28.25V43.25H25V30.75L18.2029 25.0857C17.9555 24.9152 17.72 24.72 17.5 24.5C15.5474 22.5474 15.5474 19.3815 17.5 17.4289L24.571 10.3579C26.5237 8.40525 29.6895 8.40525 31.6423 10.3579L35.1778 13.8934C37.9365 16.6521 41.387 18.2973 44.971 18.8288L44.945 23.8685C40.0838 23.3045 35.3713 21.1581 31.6423 17.4289L25.0575 24.0135ZM37.5 10.75C34.7385 10.75 32.5 8.51143 32.5 5.75C32.5 2.98857 34.7385 0.75 37.5 0.75C40.2615 0.75 42.5 2.98857 42.5 5.75C42.5 8.51143 40.2615 10.75 37.5 10.75Z"
      fill="#3A2321"
    />
  </svg>
)

export const code = (
  <svg width="52" height="47" viewBox="0 0 52 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.12565 0.875H48.8756C50.2794 0.875 51.4173 2.01296 51.4173 3.41667V44.0833C51.4173 45.4871 50.2794 46.625 48.8756 46.625H3.12565C1.72194 46.625 0.583984 45.4871 0.583984 44.0833V3.41667C0.583984 2.01296 1.72194 0.875 3.12565 0.875ZM5.66732 5.95833V41.5417H46.334V5.95833H5.66732ZM46.334 23.75L37.3479 32.7361L33.7535 29.1416L39.1451 23.75L33.7535 18.3583L37.3479 14.7639L46.334 23.75ZM12.8562 23.75L18.2479 29.1416L14.6535 32.7361L5.66732 23.75L14.6535 14.7639L18.2479 18.3583L12.8562 23.75ZM24.0799 36.4583H18.6704L27.9214 11.0417H33.3308L24.0799 36.4583Z"
      fill="#3A2321"
    />
  </svg>
)

export const soccer = (
  <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.5 0.25C39.307 0.25 50.5 11.4429 50.5 25.25C50.5 39.057 39.307 50.25 25.5 50.25C11.6929 50.25 0.5 39.057 0.5 25.25C0.5 11.4429 11.6929 0.25 25.5 0.25ZM29.6738 35.2497H21.3237L17.8763 39.9922L19.261 44.2578C21.2243 44.9018 23.3215 45.25 25.5 45.25C27.6785 45.25 29.7757 44.9018 31.739 44.2578L33.1213 39.9922L29.6738 35.2497ZM8.73385 22.4297L5.50555 24.7738L5.5 25.25C5.5 29.5742 6.87235 33.578 9.20525 36.8493L13.9813 36.8498L17.2863 32.2997L14.7164 24.3748L8.73385 22.4297ZM42.2637 22.4297L36.2812 24.3748L33.7113 32.2997L37.0163 36.8498L41.7948 36.8493C44.1278 33.578 45.5 29.5742 45.5 25.25L45.4925 24.775L42.2637 22.4297ZM25.5 19.09L19.64 23.345L21.88 30.25H29.1175L31.3575 23.345L25.5 19.09ZM31.2285 6.08248L27.9988 8.43233V14.7248L34.7337 19.6173L40.3313 17.7998L41.7168 13.542C39.1675 10.0171 35.497 7.35635 31.2285 6.08248ZM19.7689 6.08323C15.5005 7.35768 11.8304 10.019 9.28152 13.5444L10.6663 17.7998L16.2639 19.6173L22.9988 14.7248V8.43233L19.7689 6.08323Z"
      fill="#3A2321"
    />
  </svg>
)

export const run = (
  <svg width="40" height="54" viewBox="0 0 40 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.0746 18.2246L12.4999 19.8897V28.75H7.49994V16.375H7.53749L20.7085 11.5812C21.3185 11.3464 21.9805 11.2291 22.662 11.252C25.4405 11.3203 27.8805 13.1417 28.7292 15.8009C29.195 17.2608 29.62 18.2459 30.0037 18.7562C32.2845 21.7887 35.9132 23.75 40 23.75V28.75C34.5632 28.75 29.7057 26.2707 26.496 22.3815L24.752 32.2712L30 36.675V53.75H25V39.0063L19.3267 34.246L17.5075 44.5633L0.273438 41.5245L1.14166 36.6003L13.4518 38.771L17.0746 18.2246ZM26.25 10C23.4885 10 21.25 7.76143 21.25 5C21.25 2.23857 23.4885 0 26.25 0C29.0115 0 31.25 2.23857 31.25 5C31.25 7.76143 29.0115 10 26.25 10Z"
      fill="#3A2321"
    />
  </svg>
)

export const travel = (
  <svg width="52" height="43" viewBox="0 0 52 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M51.372 15.5297C51.908 17.5302 50.721 19.5865 48.7205 20.1225L9.55401 30.617C8.44591 30.914 7.27796 30.4187 6.72116 29.4157L0.164062 17.604L3.78629 16.6335L9.95576 22.745L22.694 19.3317L11.4177 1.64762L16.2473 0.353516L33.6258 16.4025L46.7793 12.878C48.7798 12.342 50.836 13.5292 51.372 15.5297ZM6.49984 37.7502H46.4998V42.7502H6.49984V37.7502Z"
      fill="#3A2321"
    />
  </svg>
)

import { initializeApp } from 'firebase/app'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyAnzdPie55iJBgrbBmyj6pVy3XHjuAhXW4',
  authDomain: 'hireleon-90c48.firebaseapp.com',
  projectId: 'hireleon-90c48',
  storageBucket: 'hireleon-90c48.appspot.com',
  messagingSenderId: '242549820625',
  appId: '1:242549820625:web:698583aad02e98b83289f0',
  measurementId: 'G-MT0YNBSV1K',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const storage = getStorage(app)

export { storage }

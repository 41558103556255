// src/App.tsx
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Home } from "./components/Home";

const App: React.FC = () => {
  const hostname = window.location.hostname;
  const company = hostname.split(".")[0];

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home company={company} />} />
      </Routes>
    </Router>
  );
};

export default App;

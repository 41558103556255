// src/HomePage.tsx
import cn from 'classnames'
import React, { useState } from 'react'
import image from '../../assets/images/leontransparent.png'
import linkedinLogo from '../../assets/images/linkedinLogo.webp'
import { AboutMe } from '../AboutMe'
import { CarrerSkills } from '../CarrerSkills'
import { Header } from '../Header'
import Modal from '../Modal'
import styles from './Home.module.scss'

interface HomePageProps {
  company: string
}

export const Home: React.FC<HomePageProps> = ({ company }) => {
  const [showAboutMeModal, setShowAboutMeModal] = useState(false)
  const [showCarrerModal, setShowCarrerModal] = useState(false)

  const openLinkedIn = () => {
    window.open('https://www.linkedin.com/in/leon-pille-7407bb235', '_blank')
  }

  return (
    <div>
      <Header company={company}></Header>

      <div className={styles.subtitleContainer}></div>
      <div className={styles.personalContainer}>
        <div className={styles.imgContainer}>
          <img src={image} alt="Leon" />
        </div>

        <div className={styles.informationContainer}>
          <div className={styles.buttonContainer}>
            <button className={cn(styles.button, styles.borderAnimation, styles.aboutMe)} onClick={() => setShowAboutMeModal(true)}>
              <span>About Me</span>
            </button>
          </div>
          <div className={styles.buttonContainer}>
            <button className={cn(styles.button, styles.borderAnimation, styles.carrer)} onClick={() => setShowCarrerModal(true)}>
              <span>Carrer & Skills</span>
            </button>
          </div>
          <div className={styles.contact}>
            <p>Contact me on</p>
            <div className={styles.linkedInLogo}>
              <img src={linkedinLogo} alt="LinkedIn" onClick={() => openLinkedIn()} />
            </div>
            <p> or at leon.pille@web.de</p>
          </div>
        </div>
      </div>

      <Modal show={showAboutMeModal} onClose={() => setShowAboutMeModal(false)}>
        <AboutMe />
      </Modal>

      <Modal show={showCarrerModal} onClose={() => setShowCarrerModal(false)}>
        <CarrerSkills />
      </Modal>
    </div>
  )
}
